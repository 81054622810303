/**
 * Import mode modules
 */
import { combineReducers } from "redux-immutable";

/**
 *  Combine the reducers
 */
const reducers = combineReducers({});

/**
 *  Export the combined reducers
 */
export default reducers;
