import { toast } from "react-toastify";

const config = {
  position: toast.POSITION.BOTTOM_LEFT,
  autoClose: 1000,
  pauseOnFocusLoss: false,
  draggable: false,
  closeOnClick: true,
  hideProgressBar: false,
  pauseOnHover: false,
  theme: "colored"
};
const configCenter = {
  position: toast.POSITION.BOTTOM_CENTER,
  autoClose: 2500,
};

/**
 * Show Success Notification
 *
 * @param {String} message [message to show]
 */
export const showSuccessMsg = (msg) => {
  toast.success(msg, config);
};

/**
 * Show Error Notification
 *
 * @param {String} message [message to show]
 */
export const showErrorMsg = (msg) => {
  toast.error(msg, config);
};

/**
 * Show Warning Notification
 *
 * @param {String} message [message to show]
 */
export const showWarningMsg = (msg) => {
  toast.warn(msg, configCenter);
};
